<template>
    <!-- Slider
    ============================================= -->
    <section id="slider" :class="sliderCssClass" ref="slider">
        <div v-swiper="swiperOption" class="swiper slider-inner">

            <div class="vertical-middle fixed-content">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 v-if="labels.h2" class="ls0 fw-bold text-uppercase" style="font-size: 48px;" data-animate="fadeInUp">{{ $cms.utils.getComputedLabel(labels.h2) }}</h2>
                            <LocalizedContent v-if="labels.text" tag="p" class="lead mb-5 fw-bold" :data-delay="labels.h2 ? 200 : 0" data-animate="fadeInUp" :localizedContent="labels.text"></LocalizedContent>
                            <div v-if="buttons.length > 0">
                                <CmsComponent v-for="(component, index) in buttons" :key="index+'_buttons'" :component="component"></CmsComponent>
                            </div>
                            <!-- <a href="#presentation" @click="onAnchorLinkClick($event, '#presentation')" class="" data-animate="fadeInUp" data-delay="400" data-scrollto="#team" data-offset="60">En savoir plus <i class="icon-line-arrow-down"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-wrapper">
                <div v-for="(picture, index) in pictures" :key="'picture-'+index" :class="slideCssClass" :style="picture.style">
                    <div class="video-overlay" style="background: rgba(0,0,0,0.3);"></div>
                </div>
            </div>
            <div v-if="pictures.length > 1" class="slider-arrow-left"><i class="icon-angle-left"></i></div>
            <div v-if="pictures.length > 1" class="slider-arrow-right"><i class="icon-angle-right"></i></div>
            <a href="#presentation" @click="onAnchorLinkClick($event, '#presentation')" class="one-page-arrow dark"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
        </div>

    </section><!-- #slider end -->
    
</template>

<style>

#slider:not(.slider-swiper) {
    flex: 1 0 auto;
}

#slider:not(.slider-swiper) .fixed-content {
    text-shadow: 2px 2px 2px black;
}

@media (min-width: 992px) {
    /* 
        We ensure that when header is fixed, we have a minimum of 102px of space before the fixed content
        This trick does not break the vertical alignment centered in the page
    */
    #slider:not(.slider-swiper) .fixed-content {
        padding-top:204px;
        transform: translate(0px, -102px);
    }
}

#slider:not(.slider-swiper) .swiper-wrapper {
    position:absolute;
    top:0px;
}

#slider:not(.slider-swiper) .swiper-slide {
    background-size: cover;
    background-color: rgb(255, 255, 255);   
    background-repeat: no-repeat;
}

#slider:not(.slider-swiper) p {
    font-size: 1.5rem;
    letter-spacing: 1px;
}


</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';

import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';

// Import Swiper Vue.js components
import { SwiperOptions} from 'swiper';
import { getSwiperOptionsOn } from '@root/src/client/theme/canvas-664/canvas.slider';
import { anchorLink } from '@root/src/client/theme/canvas-664/canvas.utils';
import { useCmsModule } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsBoolean, CmsText, CmsPicture, CmsList, CmsNumber } from '@fwk-client/modules/cms/types';
import { Component } from '@fwk-client/modules/cms/helpers/components';

/** @cmsOptions */
export interface SliderOptions {
    /** @cmsType CmsList */
    pictures?:CmsList<SliderPicture>, // The list of pictures for the slider
    /** @cmsType CmsBoolean */
    includeHeader?:CmsBoolean // if true, the slider is included in the header which is transparent.
    /** @cmsType CmsNumber */
    height?:CmsNumber // The percentage of height of the screen.
}

export interface SliderPicture {
    /** @cmsType CmsPicture */
    pictureURL:CmsPicture,
    /** @cmsType CmsText */
    position?:CmsText
}

/** @cmsLabels */
export interface SliderLabels {
  /** @cmsType CmsLabel */
  h2?:CmsLabel;
  /** @cmsType CmsLabel */
  text?:CmsLabel;
}

/** @cmsSlots */
export interface SliderSlots {
    buttons?:Component[]
}


export default defineComponent({
    props: {
        options: Object as PropType<SliderOptions>,
        labels: {
          type: Object as PropType<SliderLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<SliderSlots>
    },
    components : {
        LocalizedContent
    },
    setup(props, context) {
        const cms = useCmsModule(); 
        const headerOffset = 60;

        const slider:Ref<HTMLElement|null> = ref(null);

        const computedPictures:any[] = (props.options && props.options.pictures) ? props.options.pictures.map((sliderPicture:SliderPicture) => {
            return {
                pictureURL: sliderPicture.pictureURL,
                position: sliderPicture.position ? sliderPicture.position : "center bottom",
            }
        }).map((computedSliderPicture:SliderPicture) => {
            var url = cms.utils.getComputedPictureURL(computedSliderPicture.pictureURL)
            return {
                style: {
                    "background-image" : "url('"+url+"')", 
                    "background-position" : computedSliderPicture.position
                }
            }
        }) : [];

        const computedOptions:SliderOptions = {
            includeHeader:true,
            ...props.options
        };

        const swiperOption:SwiperOptions = {
                //slidesPerView : 3,
                //spaceBetween : 50
            navigation: {
                nextEl: '.slider-arrow-right',
                prevEl: '.slider-arrow-left',
            },
            simulateTouch:computedPictures.length > 1,
            speed: 500,
            autoplay: {
                delay: 5000
            },
            on: getSwiperOptionsOn({} as HTMLElement)
        }

        const sliderCssClass = computed(() => {
            var cssClass = "slider-element dark";
            if(computedOptions.height != undefined && computedOptions.height > 0) {
                cssClass += " min-vh-"+computedOptions.height;
            }
            else {
                cssClass += " min-vh-100";
            }
            if(computedOptions.includeHeader) {
                cssClass += " include-header"
            }
            return cssClass;
        })

        const slideCssClass = computed(() => {
            var cssClass = "swiper-slide dark";
            if(computedOptions.height != undefined && computedOptions.height > 0) {
                cssClass += " min-vh-"+computedOptions.height;
            }
            else {
                cssClass += " min-vh-100";
            }
            return cssClass;
        })

        onMounted(() => {
            animateElements(slider.value as HTMLElement)
        })

        onBeforeUnmount(() => {
            removeElementsAnimation(slider.value as HTMLElement)
        })

        const onAnchorLinkClick = (evt:Event, path:string) => {
            evt.preventDefault();
            anchorLink(path, headerOffset);
        }

        return {
            slider,
            slideCssClass,
            sliderCssClass,
            swiperOption,
            pictures:computedPictures,
            onAnchorLinkClick,
            labels:props.labels,
            buttons:props.components ? props.components.buttons : []
        }

    }
})
</script>